export default function AuxToggle($el) {
  // DOM
  this.$el = $el.parent('header')

  this.$aux = this.$el.find('.aux')

  this.$button = this.$el.find('.js-aux-toggle')
  this.$toggler = this.$button.find('.toggler')
  this.$label = this.$button.find('.text')
  this.$button.on('click', this.handleButton.bind(this))
}

AuxToggle.prototype.handleButton = function() {
  if (this.$toggler.hasClass('active')) {
    this.$toggler.removeClass('active')
    this.$label.text('Show Filters')
    this.$aux.slideUp()
  } else {
    this.$toggler.addClass('active')
    this.$label.text('Hide Filters')
    this.$aux.slideDown()
  }
}
