// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

import { Controller } from 'stimulus'
import _ from 'lodash'

export default class extends Controller {
  static targets = ['result', 'checkbox', 'multiselect', 'body', 'flyout', 'tags', 'cancel', 'apply']

  initialize() {
    // Bind functions
    this.handleClick = this.handleClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.removeTag = this.removeTag.bind(this)
    this.addTag = this.addTag.bind(this)
    this.handleResultKeyup = this.handleResultKeyup.bind(this)

    // Meta: are we rendering the tag version of a multiselect?
    this.isTagType = !this.element.classList.contains('opa-multiselect-checkboxes')

    // Meta: max number of things to select?
    this.maxSelect = this.element.dataset.maxSelect

    // Set defaults
    this.defaultText = this.resultTarget.textContent.trim() || this.resultTarget.getAttribute('placeholder') || 'Please select...'

    // Kickoff
    if (this.isTagType) {
      this.renderTags()
    } else {
      this.renderCheckboxes()
      this.applyCheckboxes()
      this.handleChange()
    }
  }
  connect() {
    this.element.addEventListener('click', this.handleClick)
    window.addEventListener('keydown', this.handleKeyPress)
    if (this.isTagType) {
      this.resultTarget.addEventListener('keyup', this.handleResultKeyup)
    }
  }
  disconnect() {
    this.element.removeEventListener('click', this.handleClick)
    window.removeEventListener('keydown', this.handleKeyPress)
    if (this.isTagType) {
      this.resultTarget.removeEventListener('keyup', this.handleResultKeyup)
    }
  }

  handleClick(event) {
    // Clicked yourself
    if (event.target === this.element) {
      this.resetCheckboxes()
    }
  }

  handleChange() {
    // Some multiselects have a max # of things to select.
    // Once that threshold is hit, disable other checkboxes
    const checkedLength = this.checkboxTargets.filter(checkbox => checkbox.checked).length
    if (this.maxSelect && checkedLength >= this.maxSelect) {
      this.checkboxTargets
        .filter(checkbox => !checkbox.checked)
        .map(checkbox => {
          $(checkbox).parent().parent().addClass('disabled')
        })
    }
    else {
      this.checkboxTargets
        .map(checkbox => {
          $(checkbox).parent().parent().removeClass('disabled')
        })
    }
  }

  handleResultKeyup(event) {
    // if result has a value, and flyout isn't open, open it
    const isOpen = this.element.classList.contains('open')
    if (event.currentTarget.value.length && !isOpen) {
      this.open()
    }
  }

  handleKeyPress(event) {
    // ESC - close if we're open
    if (event.keyCode === 27 && this.element.classList.contains('open')) {
      this.resetCheckboxes()
    }

    if (!this.isTagType) {
      // SPACE - toggle if we're focused on result
      if (event.keyCode === 32 && document.activeElement === this.resultTarget) {
        this.toggle()
        event.preventDefault()
      }

      // SPACE - reset if we're focused on cancel
      if (event.keyCode === 32 && document.activeElement === this.cancelTarget) {
        this.resetCheckboxes()
        event.preventDefault()
      }

      // SPACE - apply if we're focused on apply
      if (event.keyCode === 32 && document.activeElement === this.applyTarget) {
        this.applyCheckboxes()
        event.preventDefault()
      }

      // ENTER - apply if we pressed enter
      if (event.keyCode === 13 && this.element.classList.contains('open')) {
        this.applyCheckboxes()
        event.preventDefault()
      }
    }
  }

  // Apply user input from checkboxes to hidden multiselect
  applyCheckboxes() {
    // Get all checked checkboxes
    const checked = this.checkboxTargets.filter(checkbox => checkbox.checked)

    // Update hidden multiselect element with checkbox data.
    this.setSelectedValues(checked.map(checkbox => checkbox.value))

    // Update UI with checkbox data as display text.
    const text = checked.map(checkbox => checkbox.nextElementSibling.textContent)
    if (!this.element.dataset.noUpdateResult) {
      this.resultTarget.textContent = text.join(', ') || this.defaultText
    }

    // Close menu
    this.close()
  }

  // Reset checkbox checked states.
  resetCheckboxes() {
    // Get selected values from hidden multiselect
    const values = this.getSelectedValues()

    // Mirror checkbox checked state to select selected state
    this.checkboxTargets.map(checkbox => {
      values.indexOf(checkbox.value) < 0 ? checkbox.checked = false : checkbox.checked = true
    })

    // Close menu
    this.close()
  }

  addTag(event) {
    let values = this.getSelectedValues()
    values.push(event.currentTarget.dataset.value)

    this.setSelectedValues(values)
    this.renderTags()
    this.close()

    // Clear input
    if (!this.element.dataset.noUpdateResult) {
      this.resultTarget.value = ''
    }
  }

  removeTag(event) {
    let values = this.getSelectedValues()
    values = values.filter(val => val !== event.currentTarget.dataset.value)

    this.setSelectedValues(values)
    this.renderTags()
  }

  // Fired on init
  renderCheckboxes() {
    let items = []
    for (let i = 0; i < this.multiselectTarget.options.length; i++) {
      items.push({
        value: this.multiselectTarget.options[i].value,
        text: this.multiselectTarget.options[i].text,
        isSelected: this.multiselectTarget.options[i].selected
      })
    }

    // Add to DOM
    this.bodyTarget.innerHTML = items.map(item => checkboxBodyTemplate(item)).join('')
  }

  // Fired on init and user interaction
  // Event not always passed
  renderTags = _.debounce((event) => {
    const values = this.getSelectedValues()
    let tags = []
    let items = []
    for (let i = 0; i < this.multiselectTarget.options.length; i++) {
      const item = {
        value: this.multiselectTarget.options[i].value,
        text: this.multiselectTarget.options[i].text,
        isSelected: this.multiselectTarget.options[i].selected
      }

      // Some tag data should be displayed as "addable", some "removeable"
      values.indexOf(item.value) < 0 ? items.push(item) : tags.push(item)
    }

    // Add to DOM (selected tags below dropdown)
    // Don't update rendered tags when searching.
    if (!event) {
      this.tagsTarget.innerHTML = tags.map(tag => tagTemplate(tag)).join('')
    }

    // Filter out tag content from dropdown
    // take into account user search
    let typeahead = ''
    if (event) {
      typeahead = event.target.value.toLowerCase()
    }
    // find items to render in dropdown
    const newItems = items
      .filter(item => item.text.toLowerCase().indexOf(typeahead) >= 0)
      .map(item => tagListTemplate(item))
      .join('') || tagListEmptyTemplate()

    // add them to DOM
    this.bodyTarget.innerHTML = newItems
  }, 250)

  // getter/setter
  // Keep hidden multiselect updated with user interactions
  getSelectedValues() {
    return $(this.multiselectTarget).val() || []
  }
  setSelectedValues(values) {
    $(this.multiselectTarget).val(values).trigger('change')
  }

  // Simple UI functions
  toggle() {
    this.element.classList.contains('open') ? this.close() : this.open()
  }
  open() {
    this.element.classList.add('open')
  }
  close() {
    this.isTagType ? this.bodyTarget.scrollTop = 0 : this.flyoutTarget.scrollTop = 0
    this.element.classList.remove('open')
    this.resultTarget.focus()
  }
}

function checkboxBodyTemplate(checkbox) {
  let checked = ''
  if (checkbox.isSelected) { checked = ' checked' }
  return `<div class="field field-checkbox">
            <label>
              <input type="checkbox" value="${checkbox.value}" data-target="multiselect.checkbox"${checked} data-action="change->multiselect#handleChange" />
              <span>${checkbox.text}</span>
            </label>
          </div>`.replace(/\n\s+/g, '')
}

function tagListTemplate(tag) {
  return `<div class="field" tabindex="0">
            <label data-action="click->multiselect#addTag" data-value="${tag.value}">
              <span>${tag.text}</span>
            </label>
          </div>`.replace(/\n\s+/g, '')
}
function tagListEmptyTemplate() {
  return `<div class="field">
            <label>
              <span>Sorry, no results ...</span>
            </label>
          </div>`.replace(/\n\s+/g, '')
}
function tagTemplate(tag) {
  return `<span class="opa-multiselect-tag" data-action="click->multiselect#removeTag" data-value="${tag.value}" tabindex="0">
            <i class="material-icons">clear</i>
            ${tag.text}
          </span>`.replace(/\n\s+/g, '')
}
