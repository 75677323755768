export default function SearchSort($form) {
  // DOM
  this.$sort = $('#search-sort .sort')
  this.$filter = $('#search-sort .search-sort-filter')
  this.$flyout = $('#search-sort .search-sort-flyout')
  this.$cardwrap = $('.card-wrap')
  this.$form = $form
  this.$order = this.$form.find('input[name="order"]')

  // Events
  this.$sort.on('click', this.handleSortClick.bind(this))
  this.$filter.on('click', this.handleFilterClick.bind(this))
}

SearchSort.prototype.handleSortClick = function(event) {
  const sort = $(event.currentTarget).data('sort')
  this.$order.val([sort])
  this.$cardwrap.addClass('js-blur')
  this.$form.submit()
}

SearchSort.prototype.handleFilterClick = function(event) {
  this.$flyout.slideToggle()
}
