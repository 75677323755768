export default function Navigation() {
  // DOM
  this.$body = $('body')
  this.$nav = $('#navigation')
  this.$navInner = this.$nav.find('#navigation-inner')
  this.$navToggle = $('#navigation-toggle')
  this.$navLinks = $('.nav-wrap a')
  
  this.$researchToggle = this.$nav.find('#research-toggle')
  this.$researchMenu = this.$nav.find('#research-menu')

  this.$accountToggle = $('#account-toggle')
  this.$accountMenu = $('#account-menu')

  this.$submenuWrap = this.$nav.find('.js-submenu-wrap')
  this.$submenuToggle = this.$nav.find('.js-toggle-submenu')
  this.$submenuBack = this.$nav.find('.js-submenu-back')

  // Events
  this.$navToggle.on('click', this.handleNavToggle.bind(this))
  this.$navInner.on('click', this.handleNavInnerClick.bind(this))
  this.$researchToggle.on('click', this.handleResearchToggle.bind(this))
  this.$accountToggle.on('click', this.handleAccountToggle.bind(this))
  this.$submenuToggle.on('click', this.handleSubmenuToggle.bind(this))
  this.$submenuBack.on('click', this.handleSubmenuBack.bind(this))
  this.$navLinks.on('click', this.handleHttpCheck.bind(this))
}

Navigation.prototype.handleNavToggle = function() {
  const isOpen = this.$nav.hasClass('open')
  isOpen ? this.hideNavigation() : this.showNavigation()
}
Navigation.prototype.showNavigation = function() {
  this.hideAccount()

  // prevent body scrolling
  this.$body.addClass('no-scroll')

  // show navigation
  this.$navToggle.find('.toggler').addClass('active')
  this.$nav.addClass('open')
  this.$navInner.removeClass('open')
}
Navigation.prototype.hideNavigation = function() {
  // enable body scrolling
  this.$body.removeClass('no-scroll')

  // hide navigation
  this.$navToggle.find('.toggler').removeClass('active')
  // this.$nav.removeClass('open no-scroll')
  this.$nav.removeClass('open')

  // wait for nav to close before resetting subnavs
  if (this.$navInner.hasClass('open')) {
    setTimeout(() => {
      this.$navInner.removeClass('open')
    }, 400)
  }

  // Close research nav
  this.$researchToggle.removeClass('active')
  this.$researchMenu.removeClass('open')

  // hide all submenus
  this.hideAllSubmenus()
}



Navigation.prototype.handleAccountToggle = function() {
  const isOpen = this.$accountMenu.hasClass('open')
  isOpen ? this.hideAccount() : this.showAccount()
}
Navigation.prototype.showAccount = function() {
  this.hideNavigation()
  this.$accountToggle.find('.toggler').addClass('active')
  this.$accountMenu.addClass('open')
}
Navigation.prototype.hideAccount = function() {
  this.$accountToggle.find('.toggler').removeClass('active')
  this.$accountMenu.removeClass('open')
}



Navigation.prototype.handleResearchToggle = function() {
  const isOpen = this.$researchMenu.hasClass('open')
  isOpen ? this.hideResearchMenu() : this.showResearchMenu()
}
Navigation.prototype.showResearchMenu = function() {
  this.hideAccount()
  this.$researchToggle.addClass('active')
  this.$researchMenu.addClass('open')
}
Navigation.prototype.hideResearchMenu = function() {
  this.$researchToggle.removeClass('active')
  this.$researchMenu.removeClass('open')
  this.hideAllSubmenus()
}



// close all submenus if user clicks away
Navigation.prototype.handleNavInnerClick = function(event) {
  if (event.target === event.currentTarget) {
    this.hideAllSubmenus()
  }
}
Navigation.prototype.handleSubmenuToggle = function(event) {
  const $submenuWrap = $(event.currentTarget).parent()
  const isActive = $submenuWrap.hasClass('active')
  isActive ? this.hideAllSubmenus() : this.showSubmenu($submenuWrap)
}
Navigation.prototype.showSubmenu = function($menu) {
  this.$submenuWrap.removeClass('active')
  $menu.addClass('active')
  this.$researchMenu.addClass('submenu-active')
  this.$navInner.addClass('open')
  // this.$nav.addClass('no-scroll').animate({scrollTop: 0}, 200)
  this.$nav.animate({scrollTop: 0}, 200)
}
Navigation.prototype.hideAllSubmenus = function() {
  this.$submenuWrap.removeClass('active')
  this.$researchMenu.removeClass('submenu-active')
  // this.$nav.removeClass('no-scroll')

  // wait for menu to close before resetting subnav
  setTimeout(() => {
    this.$navInner.removeClass('open')
  }, 400)
}
Navigation.prototype.handleSubmenuBack = function() {
  this.$navInner.removeClass('open')
  this.$researchMenu.removeClass('submenu-active')

  // wait for menu to close before resetting subnav
  // mobile only
  let mql = window.matchMedia('(min-width: 768px)')
  if (mql.matches) {
    this.$submenuWrap.removeClass('active')
  } else {
    setTimeout(() => {
      this.$submenuWrap.removeClass('active')
    }, 400)
  }
}



Navigation.prototype.handleHttpCheck = function(event) {
  const href = $(event.currentTarget).attr('href')
  // console.log(href, location.pathname)
  if (href === location.pathname) {
    event.preventDefault()
  }
}
