export default function Toggler(els) {
  this.togglers = []

  els.map(el => {
    const toggler = togglerFactory($(el))
    this.togglers.push(toggler)
    toggler.$button.on('click', () => {
      this.handleToggle(toggler)
      this.handleScrollTo(toggler)
    })
  })

  // set initial state of togglers
  this.togglers.map((t, i) => {
    i > 0 ? t.hideFolder() : t.showFolder()
  })
}

Toggler.prototype.handleToggle = function(toggler) {
  // Always toggle whatever was toggled
  toggler.handleToggle()

  // ...filter out the toggled toggler, close the rest
  this.togglers
    .filter(t => t !== toggler)
    .map(t => t.hideFolder())
}

Toggler.prototype.handleScrollTo = function(toggler) {
  // scroll to the toggle button under specific instances
  if (toggler.isOpen() && toggler.scrollTo()) {
    setTimeout(() => {
      $('html, body').animate({
        scrollTop: toggler.$button.offset().top - 90
      }, 400)
    }, 400)
  }
}

const togglerFactory = $el => ({
  $toggle: $el.find('.toggler'),
  $button: $el.find('.js-toggle-button'),
  $folder: $el.find('.js-toggle-folder'),
  handleToggle () {
    this.isOpen() ? this.hideFolder() : this.showFolder()
  },
  isOpen () {
    return this.$toggle.hasClass('active')
  },
  scrollTo () {
    return $el.hasClass('js-scroll-to')
  },
  hideFolder () {
    this.$toggle.removeClass('active')
    this.$folder.slideUp()
  },
  showFolder () {
    this.$toggle.addClass('active')
    this.$folder.slideDown()
  }
})
