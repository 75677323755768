// import _ from 'lodash'

export default function ViewMore($el) {
  // DOM
  this.$el = $el
  this.$button = $el.find('.js-view-more-button')
  this.$label = $el.find('.js-view-more-label')
  this.$copy = $el.find('.js-view-more-copy')
  this.$toggler = $el.find('.toggler')

  // button text
  this.buttonLabel = {}
  this.buttonLabel.open = $el.attr('data-open-label') || 'View Less'
  this.buttonLabel.close = $el.attr('data-close-label') || 'View More'

  // Events
  this.$button.on('click', this.handleClick.bind(this))
}

ViewMore.prototype.handleClick = function(event) {
  this.$button.hasClass('open') ? this.closeCopy() : this.openCopy()
}

ViewMore.prototype.openCopy = function() {
  this.$copy.slideDown()
  this.$button.addClass('open')
  this.$label.text(this.buttonLabel.open)
  this.$toggler.addClass('active')
}

ViewMore.prototype.closeCopy = function() {
  this.$copy.slideUp()
  this.$button.removeClass('open')
  this.$label.text(this.buttonLabel.close)
  this.$toggler.removeClass('active')
}
