export default function ProductCardToggler(els) {
  this.togglers = []

  els.map(el => {
    const toggler = togglerFactory($(el))
    this.togglers.push(toggler)
    toggler.$el.on('click', () => this.handleToggle(toggler))
  })
}

ProductCardToggler.prototype.handleToggle = function(toggler) {
  // Always toggle whatever was toggled
  toggler.handleToggle()

  // If we're not on the search page...
  if ($('#search-page').length) {
    return
  }

  // And we're not on a mobile device...
  if (window.matchMedia('(max-width: 768px)').matches) {
    return
  }

  // ...filter out the toggled toggler, close the rest
  this.togglers
    .filter(t => t !== toggler)
    .map(t => t.hideFiles())
}


const togglerFactory = ($el) => ({
  $el,
  $toggler: $el.find('.toggler'),
  $fileWrap: $el.parent().next('.file-wrap'),
  handleToggle () {
    this.isOpen() ? this.hideFiles() : this.showFiles()
  },
  isOpen () {
    return this.$toggler.hasClass('active')
  },
  hideFiles () {
    this.$toggler.removeClass('active')
    this.$fileWrap.slideUp()
  },
  showFiles () {
    this.$toggler.addClass('active')
    this.$fileWrap.slideDown()
  }
})
