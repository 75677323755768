const FUDGE_FACTOR = 20           // seconds we're probably off of server time by
const SECONDS_TO_LOGOUT = 15 * 60 - FUDGE_FACTOR
const SECONDS_TO_LOGOUT_CMS = 10 * 60 - FUDGE_FACTOR

export default function Timeout() {
  // Die if not logged in
  if (APP && !APP.loggedInUser) return

  // DOM
  this.$button = $('#timeout-button')
  this.$seconds = $('.js-timeout-seconds')
  this.$stay = $('.js-timeout-stay')
  this.$close = $('.opa-modal-close')

  // Events
  this.$stay.on('click', this.handleStay.bind(this))
  $(window).on('mousemove', this.handleStayMaybe.bind(this))
  $(window).on('keydown', this.handleStayMaybe.bind(this))

  // State
  this.resetSeconds()

  // Kickoff
  setInterval(() => {
    this.seconds--
    this.render()
  }, 1000)

  // need this to send session to rails
  // $(document).ajaxSend(function(e, xhr, options) {
  //   var token = $("meta[name='csrf-token']").attr('content');
  //   xhr.setRequestHeader('X-CSRF-Token', token);
  // });
}

Timeout.prototype.handleStayMaybe = function(){
  // console.log('seconds ' + this.seconds)
  if(this.seconds < 300){
    // console.log("handleStay")
    this.handleStay()
  }
}

Timeout.prototype.resetSeconds = function() {
  this.seconds = APP.cmsPage ? SECONDS_TO_LOGOUT_CMS : SECONDS_TO_LOGOUT
}

Timeout.prototype.render = function() {
  // console.log(this.seconds)

  // open modal
  if (this.seconds == 60) {
    this.$button.trigger('click')
  }

  // update display of seconds remaining
  if (this.seconds < 60 && this.seconds >= 0) {
    this.$seconds.text(this.seconds)
  }

  // log user out
  if (this.seconds < 0) {
    window.location = APP.okta_logout_url
  }
}

Timeout.prototype.handleStay = function() {
  $.get('/refresh')
  this.resetSeconds()
  this.$close.trigger('click')
}
