export default function GoogleTagManager() {
  // DOM
  this.$form = $('form.search-wrap')

  // Events
  this.$form.on('submit', this.handleSearchSubmit.bind(this))
}

GoogleTagManager.prototype.handleSearchSubmit = function(e) {
  if(typeof gtag !== 'undefined'){
    console.log('gtag');
    gtag('event', 'submit', {
      'event_category': 'Global Search Keyword',
      'event_label': $(e.target).find('input').val(),
    })
  }
}
