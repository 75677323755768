// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal']

  initialize() {
    this.handleModalClick = this.handleModalClick.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  connect() {
    this.modalTarget.addEventListener('click', this.handleModalClick)
  }

  disconnect() {
    this.modalTarget.removeEventListener('click', this.handleModalClick)
  }

  handleKeyPress(event) {
    if (event.keyCode === 27) {
      this.close()
    }
  }

  handleModalClick(event) {
    // clicked yrself
    if (event.target === this.modalTarget) {
      this.close()
    }
  }

  setModal(name) {
    if (name) {
      this.modal = this.modalTargets.find(modal => modal.dataset.name === name)
    } else {
      this.modal = this.modalTarget
    }
  }

  open(event) {
    this.setModal(event.currentTarget.dataset.name)
    this.modal.classList.add('open')
    this.modal.style.zIndex = '9999'
  }

  close(event) {
    this.modalTargets.map(modal => modal.classList.remove('open'))
    setTimeout(() => {
      this.modalTargets.map(modal => modal.style.zIndex = '-1')
    }, 400)
  }
}
