export default function Consent() {
  // DOM
  this.$button = $('#consent-button')
  this.$consent = $('.js-consent-ok')

  // Events
  this.$consent.on('click', this.handleConsent.bind(this))

  // Kickoff
  const consent = localStorage.getItem('consent')
  if (!consent) this.$button.trigger('click')
}

Consent.prototype.handleConsent = function() {
  localStorage.setItem('consent', true)
}
