// heads up, this one is written factory style because I guess I went crazy ??
export default function SearchFilter($el) {
  const filter = {
    $el,
    values: [],
    param: $el.data('param'),
    $inputs: $el.find('input'),
    $button: $el.find('.opa-button'),
    $toggler: $el.find('.toggler'),
    $flyout: $el.find('.filter-flyout'),
    $cancel: $el.find('.js-cancel'),
    $apply: $el.find('.js-apply'),
    $clear: $el.find('.js-clear'),
    $cardwrap: $('.card-wrap'),

    setValues: (values) => {
      filter.values = values
      filter.render(values)
    },
    updateValues: () => {
      filter.$cardwrap.addClass('js-blur')
      const name = filter.param
      const data = filter.$inputs
        .toArray()
        .filter(input => $(input).is(':checked:not(:disabled)'))
        .map(input => $(input).val())

      filter.$el.trigger('filter:updateValues', {name, data})
    },
    render: (values) => {
      filter.$inputs.map(function(i, input) {
        const $el = $(input)
        const match = values.find(val => val === $el.val())
        match ? $el.prop('checked', true) : $el.prop('checked', false)
      })
    },

    handleButtonPress: () => {
      const isOpen = filter.$toggler.hasClass('active') ? true : false
      filter.$el.trigger('filter:closeAll')
      if (!isOpen) {
        filter.openFlyout()
      }
    },

    openFlyout: () => {
      filter.$toggler.addClass('active')
      filter.$flyout.slideDown('fast')
    },
    closeFlyout: () => {
      filter.$toggler.removeClass('active')
      filter.$flyout.slideUp('fast')
    },
    uncheckAll: () => {
      filter.render([])
    },
    handleCancel: () => {
      // filter.render(filter.values)
      filter.closeFlyout()
    }
  }

  filter.$button.on('click', filter.handleButtonPress)
  filter.$cancel.on('click', filter.handleCancel)
  filter.$apply.on('click', filter.updateValues)
  filter.$clear.on('click', filter.uncheckAll)

  return filter
}
