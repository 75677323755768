import _ from 'lodash'

export default function ScrollyMenu($el) {
  // DOM
  this.$window = $(window)
  this.$el = $el
  this.$items = $el.find('.menu-item')
  this.$sections = this.$items.map((i, item) => {
    return $(item.dataset.section)[0]
  })

  // Events
  this.$window.on('scroll', _.throttle(this.handleScroll.bind(this), 25))
  this.$window.on('resize', _.debounce(this.handleResize.bind(this), 250))
  this.$items.on('click', this.handleClick.bind(this))

  // Kickoff
  this.updateMagicNums()
  this.handleScroll()
}

ScrollyMenu.prototype.handleScroll = function() {
  let currentSection = undefined
  const windowScrollTop = this.$window.scrollTop()
  let depth = windowScrollTop + this.$el.height() + this.scrollyOffset - this.scrollySpacer
  depth = depth * 1.15; // fudge factor

  // onscroll loop through all sections to see if we've "passed" any.
  this.$sections.each((i, section) => {
    const $section = $(section)
    if (Math.floor($section.offset().top) > depth) {
      return
    }
    currentSection = '#' + $section.attr('id')
  })

  // Set nav to detached once we hit a certain point on the page.
  windowScrollTop > this.scrollyDepth ? this.$el.addClass('detached') : this.$el.removeClass('detached')

  // Die if we didn't go anywhere
  if (this.currentSection === currentSection) { return }
  this.currentSection = currentSection;

  // Update nav items
  this.$items.removeClass('active')
  if (this.currentSection) {
    this.$items.filter((i, item) => {
      return $(item).data('section') === currentSection
    })
    .addClass('active')
  }
}

ScrollyMenu.prototype.handleResize = function() {
  this.updateMagicNums()
}
ScrollyMenu.prototype.handleClick = function(event) {
  const currentSection = event.currentTarget.dataset.section
  this.scrollToSection(currentSection);
}

ScrollyMenu.prototype.updateMagicNums = function() {
  let mql = window.matchMedia('(min-width: 768px)')
  if (mql.matches) {  // desktop
    this.scrollySpacer = this.$el.height() / 2
    this.scrollyOffset = $('#site-bar').height() + 30
  } else {  // mobile
    this.scrollySpacer = 0
    this.scrollyOffset = $('#site-bar').height()
  }

  // All
  this.scrollyDepth = this.$el.offset().top - this.scrollyOffset
}

ScrollyMenu.prototype.scrollToSection = function(currentSection) {
  const $section = $(currentSection)
  const offset = Math.floor($section.offset().top) - this.scrollyOffset - this.scrollySpacer
  const depth = this.$window.scrollTop()
  const ms = this.getScrollTime(depth, offset)

  $('html, body').animate({scrollTop: offset}, ms)
}

ScrollyMenu.prototype.getScrollTime = function(currentDepth, distance) {
  const pixelsPerMs = 1.8
  const ms = Math.round(Math.abs(currentDepth - distance) / pixelsPerMs)

  // page should scroll at least a 1/4 second.
  if (ms < 250) { return 250 }

  // page shouldn't scroll longer than one second.
  if (ms > 1000) { return 1000 }

  return ms
}
