export default function YTModal($el) {
  // DOM
  this.slug = $el.data('modal')
  this.$body = $('body')
  this.$modal = $('#' + this.slug)

  // Events
  $el.on('click', this.handleModalOpen.bind(this))
  this.$modal.on('click', this.handleModalClose.bind(this))
}

// because the modal is ONLY a youtube vid, and the vid swallows
// all clicks, any click on the modal is a click to close
YTModal.prototype.handleModalClose = function(event) {
  this.$body.removeClass('no-scroll')
  this.$modal.removeClass('open')

  // clone and re-inject to kill yt player
  const $tmpModal = this.$modal.clone(true)
  this.$modal.remove()
  this.$modal = $tmpModal.appendTo($('#page'))
}

YTModal.prototype.handleModalOpen = function(event) {
  this.$body.addClass('no-scroll')
  this.$modal.addClass('open')
}
