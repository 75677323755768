export default function AccessRequest() {
  // DOM
  this.$classification = $('#access_request_classification')
  this.$jamrsYN = $('#access_request_jamrs_yn')
  this.$dodID = $('#access_request_smart_card_num')
  this.$pocName = $('#access_request_opa_poc_name')
  this.$pocEmail = $('#access_request_opa_poc_email')
  this.$reasonForAccess = $('#access_request_reason_for_access')

  // Events
  // TODO: these two functions should be combined
  this.$classification.on('change', this.handleClassification.bind(this))
  this.$jamrsYN.on('change', this.handleJamrsYN.bind(this))

  // Kickoff
  this.render()
}

AccessRequest.prototype.render = function() {
  this.handleClassification()
  this.handleJamrsYN()
}

AccessRequest.prototype.handleClassification = function() {
  const val = this.$classification.val()
  const isChecked = this.$jamrsYN.is(':checked')

  // show DOD ID # field
  if (val == 'I am a DoD civilian or Service member' || val == 'I am a DoD contractor with a CAC') {
    this.makeRequired(this.$dodID)
    this.showField(this.$dodID)
  } else {
    this.hideField(this.$dodID)
    this.unmakeRequired(this.$dodID)
  }

  // mark "reason for request" as required
  if (!isChecked) {
    if (val == 'None of the above') {
      this.makeRequired(this.$reasonForAccess)
    } else {
      this.unmakeRequired(this.$reasonForAccess)
    }
  }
}
AccessRequest.prototype.handleJamrsYN = function() {
  const val = this.$classification.val()
  const isChecked = this.$jamrsYN.is(':checked')
  if (isChecked) {
    this.makeRequired(this.$pocName)
    this.makeRequired(this.$pocEmail)
    this.makeRequired(this.$reasonForAccess)
  } else {
    this.unmakeRequired(this.$pocName)
    this.unmakeRequired(this.$pocEmail)
    if (val != 'None of the above') {
      this.unmakeRequired(this.$reasonForAccess)
    }
  }
}

AccessRequest.prototype.showField = function($el) {
  $el.parent()
    .removeClass('hidden')
}
AccessRequest.prototype.hideField = function($el) {
  $el.parent()
    .addClass('hidden')
}
AccessRequest.prototype.makeRequired = function($el) {
  $el.attr('required', true)
    .prev()
    .children()
    .removeClass('hidden')
}
AccessRequest.prototype.unmakeRequired = function($el) {
  $el.attr('required', false)
    .prev()
    .children()
    .addClass('hidden')
}
