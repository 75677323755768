import AuxToggle from './AuxToggle'
import SearchSort from './SearchSort'

export default function SearchSecondary($el) {
  // DOM
  this.$el = $el
  this.$form = $el.find('form')

  this.$filtersToggle = $el.find('.js-filters-toggle')
  this.$filtersTarget = $el.find('.js-filters')
  this.$filters = $('.filter-wrap .opa-button')
  this.$cardwrap = $('.card-wrap')

  // Events
  this.$filtersToggle.on('click', this.handleFiltersToggle.bind(this))
  this.$filters.on('click', this.handleFilterClick.bind(this))

  // handle aux toggle
  new AuxToggle($('#aux-toggle'))

  // handle sorting
  new SearchSort(this.$form)
}

SearchSecondary.prototype.handleFiltersToggle = function(event) {
  const $toggler = this.$filtersToggle.find('.toggler')

  if ($toggler.hasClass('active')) {
    $toggler.removeClass('active')
    this.$filtersTarget.slideUp()
  } else {
    $toggler.addClass('active')
    this.$filtersTarget.slideDown()
  }
}

SearchSecondary.prototype.handleFilterClick = function(event) {
  const $target = $(event.currentTarget)
  if ($target.hasClass('disabled')) { return }

  this.$cardwrap.addClass('js-blur')
  const value = $target.data('value')
  const name = $target.data('name')
  const isActive = $target.hasClass('outline') ? false : true

  const $select = this.$form.find('select[name="' + name + '"]')
  const selectVal = $select.val() || []

  // Special case for "All X" buttons
  if (!value) {
    if (!isActive) {
      $select.val([])
      this.$form.submit()
      return
    }
    return
  }

  // add/subtract for individual filters...
  if (isActive) {
    const pos = selectVal.indexOf(value.toString());
    if (pos > -1) {
      selectVal.splice(pos, 1);
    }
  } else {
    selectVal.push(value)
  }

  $select.val(selectVal)
  this.$form.submit()
}
