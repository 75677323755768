import AuxToggle from './AuxToggle'
import SearchFilter from './SearchFilter'
import SearchSort from './SearchSort'

export default function SearchMain($el) {
  // DOM
  this.$el = $el
  this.$form = $el.find('form')
  this.filters = $el.find('.js-filter').map(function() {
    return SearchFilter($(this))
  })

  // bind functions
  this.filterCloseAll = this.filterCloseAll.bind(this)
  this.updateSearchForm = this.updateSearchForm.bind(this)

  // // set default checkbox states
  // this.$form.find('select').map(function(i, select) {
  //   const $el = $(select)
  //   const name = $el.attr('name')

  //   this.filters
  //     .toArray()
  //     .find(filter => filter.param === name)
  //     .setValues($el.val())
  // }.bind(this))

  // attach listeners
  this.filters.map(function(i, filter) {
    filter.$el.on('filter:closeAll', this.filterCloseAll)
    filter.$el.on('filter:updateValues', this.updateSearchForm)
  }.bind(this))

  // handle aux toggle
  new AuxToggle($('#aux-toggle'))

  // handle sorting
  new SearchSort(this.$form)
}

SearchMain.prototype.filterCloseAll = function() {
  this.filters.map(function() {
    this.closeFlyout()
  })
}

SearchMain.prototype.updateSearchForm = function(event, {name, data}) {
  this.$form
    .find('select[name="' + name + '"]')
    .val(data)
  this.$form.submit()
}
